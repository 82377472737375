import React, { Component } from 'react'

import Layout from "../layouts/App"
import SEO from "../components/seo"

export default class NotFoundPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Page not found" />
        <article className="error_page">
          <section className="container-fluid-custom-only content-body">
            <h1>Sorry page not found</h1>
          </section>
        </article>
      </Layout>
    )
  }
}
